@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Epilogue", sans-serif;

}

@font-face {
    font-family: Larose;
    src: url(../src/assets/fonts/LaRosa.otf);
}

html,body{
    font-family: "Epilogue", sans-serif;

}
.css-13xfq8m-MuiTabPanel-root{
    padding: 0% !important;
}


.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .css-13fy093{
    background-color: #E8f5ff;

  }
  .css-ldo4d5 {
    background-color: white;
  }
  
  ::-webkit-scrollbar{
    width: 8px;
  }
  ::-webkit-scrollbar-track{
    /* background: #d1e5ff; */
  }
  ::-webkit-scrollbar-thumb{
    /* border-radius: 20px; */
    border-radius: 10px;
    height: 150px;
    background: #003349;
  }

  
  

