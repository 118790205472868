.Homeportfolio-crousel{
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: rgba(0, 39, 55, 1); */
    position: absolute;
    top: 0;
  }
  
  .swiper-main{
    /* background-color: rgb(212, 166, 255); */
    padding-left: 6%;
  }
  .portfolio-heading{
    width: 90%;
    margin-top: 3vh;
    /* margin-left: 15vh; */
    margin-left: 6%;
    margin-bottom: 10vh;
  }
  
  .portfolio-heading h1{
    font-family: "larose";
    font-size: 33px;
    margin-bottom: 1%;
    font-weight: 500;
    color: white;
  }
  .custom-navigation{
    margin-top: 10vh;
    display: flex;
    align-items: center;
    display: none !important;

  }
  @media only screen and (max-width: 700px) {
    .custom-navigation {
     display: initial !important;
     display: flex !important;

    }
  }
  .AllProjects-Portfoliobtn{
    margin-left: 5%;
  }
  
  .custom-prev-button{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: none;
    border: 1.2px solid #181818;
    margin-left: 10px;
  }
  .custom-prev-button:hover{
    cursor: pointer;
  }
  .custom-next-button:hover{
    cursor: pointer;
  }
  
  .custom-next-button{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: none;
    border: 1.2px solid #181818;
    margin-left: 10px;
  }
  .firstheading-portfolio{
    font-family: "DM Sans";
    font-size: 26px;
    font-weight: 500;
  }
  .secondheading-portfolio{
    font-family: "DM Sans18ptLight";
    font-size: 22px;
    font-weight: 500;
   
  }
  
  .swiper-slide-section{
    transition: all 1s ease-in;
  
  }
  
  .swiper-slide-section img{
    transition: all 0.5s;
    margin-bottom: 2vh;
  }
  
  .swiper-slide{
    background-color: transparent !important;
    text-align: left;
  }
  
  /* .swiper-slide-section img:hover{ */
    /* border: 2px solid orange; */
    /* transform: scale(1.1); */
    /* transform-origin: center center;  */
    /* border: 1px solid orange; */
    
  /* } */
  
  
  
  @media only screen and (max-width: 700px) {
    .AllProjects-Portfoliobtn{
      margin-left: 0%;
    }
    .custom-navigation{

      margin-top: 1vh !important;
      display: "flex" !important;
      justify-content: "center" !important;
      margin-left: 36%;
     
    }
    .swiper-main{
      /* background-color: rgb(212, 166, 255); */
      padding-left: 15%;
    }
  }
  
  
  
  